import { DEFAULT_RETRY_STRATEGY } from '@/constants'
import { DeliveryClient, IDeliveryClientConfig } from '@kontent-ai/delivery-sdk'
import packageInfo from '../package.json'

const sourceTrackingHeaderName = 'X-KC-SOURCE'

export function getDeliveryClient(options?: Partial<IDeliveryClientConfig>) {
	return new DeliveryClient({
		projectId: process.env.NEXT_PUBLIC_KONTENT_PROJECT_ID,
		previewApiKey: process.env.KONTENT_PREVIEW_API_KEY,
		globalHeaders: (_queryConfig) => [
			{
				header: sourceTrackingHeaderName,
				value: `${packageInfo.name};${packageInfo.version}`,
			},
		],
		assetsDomain: process.env.NEXT_PUBLIC_ASSETS_BASE_PATH,
		retryStrategy: DEFAULT_RETRY_STRATEGY,
		...(options || {}),
	})
}
