import filesize from 'filesize'
export const getFilesizeFormatter = (
	options = {
		base: 2,
		standard: 'jedec',
		roundingMethod: 'floor',
	},
) => {
	return filesize.partial(options as any)
}
