import { TeachingAdviceLinkResult } from '@/types'
import { CommonPageAPIType, PAGE_API_BASE_PATH } from '.'
import { commonFetch, getBaseHost } from '../fetchUtils'

export const fetchPageApiTeachingAdviceLink = async ({
	codename,
	stage,
	year,
}: {
	codename: string
	stage?: string
	year?: string
}) => {
	let baseHost = getBaseHost()
	let paths = [codename, stage, year].filter(Boolean).join('/')

	const { ok, json } = await commonFetch<
		CommonPageAPIType<TeachingAdviceLinkResult>,
		null
	>(
		`${baseHost}/${PAGE_API_BASE_PATH}/page-api/teaching-advice-link/${paths}.json`,
		null,
		{
			method: 'GET',
		},
	)

	if (ok) {
		return json
	}
}
