import { FilterParams } from '@/types'
import type {
	DeliveryClient,
	IContentItem,
	Responses,
	SortOrder,
} from '@kontent-ai/delivery-sdk'
import { client } from './client'
import { fnReturnData } from './fnReturnData'

export function getAllItemsByType<T extends IContentItem>({
	type,
	depth = 1,
	order = null,
	elementsParameter = null,
	containsFilter = null,
	allFilter = null,
	anyFilter = null,
	equalsFilter = null,
	notEmptyFilter = null,
	notEqualsFilter = null,
	inFilter = null,
	preview,
	kontentClient = client,
}: {
	type: string
	depth?: number
	order?: { element: string; sortOrder: SortOrder }
	elementsParameter?: string[]
	containsFilter?: FilterParams
	allFilter?: FilterParams
	anyFilter?: FilterParams
	equalsFilter?: { element: string; value: string }
	notEmptyFilter?: { element: string }
	notEqualsFilter?: { element: string; value: string }
	inFilter?: FilterParams
	preview: boolean
	kontentClient?: DeliveryClient
}): Promise<Responses.IListContentItemsResponse<T>> {
	let temp = kontentClient.items<T>().type(type).depthParameter(depth)
	if (order) {
		temp = temp.orderParameter(order.element, order.sortOrder)
	}
	if (elementsParameter) {
		temp = temp.elementsParameter(elementsParameter)
	}
	if (containsFilter) {
		temp = temp.containsFilter(containsFilter.element, containsFilter.value)
	}
	if (allFilter) {
		temp = temp.allFilter(allFilter.element, allFilter.value)
	}
	if (anyFilter) {
		temp = temp.anyFilter(anyFilter.element, anyFilter.value)
	}
	if (equalsFilter) {
		temp.equalsFilter(equalsFilter.element, equalsFilter.value)
	}
	if (notEmptyFilter) {
		temp.notEmptyFilter(notEmptyFilter.element)
	}
	if (notEqualsFilter) {
		temp.notEqualsFilter(notEqualsFilter.element, notEqualsFilter.value)
	}
	if (inFilter) {
		temp.inFilter(inFilter.element, inFilter.value)
	}
	return temp
		.queryConfig({ usePreviewMode: preview })
		.toPromise()
		.then(fnReturnData)
}
