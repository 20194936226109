import { STAGE_YEARS } from '@/constants'
import {
	Focusarea,
	Focusareaoption,
	Optionslist,
	Syllabus,
} from '@/kontent/content-types'
import { contentTypes } from '@/kontent/project/contentTypes'
import {
	TaxoKeyLearningArea,
	TaxoStage,
	TaxoStageYear,
} from '@/kontent/taxonomies'
import { getItemByCodename } from '@/lib/api'
import { getAllItemsByType } from '@/lib/getAllItemsByType'
import {
	CommonSyllabusPathParams,
	getStageOrYearFocusAreas,
} from '@/pages/learning-areas/[learningarea]/[syllabus]/[tab]/[stage]/[...afterStageSlugs]'
import { TaxoStageWithLifeSkill, TaxoStageYearWithLifeSkill } from '@/types'
import {
	byTaxoCodename,
	getCodenameBySlug,
	getLinkedItems,
	getSlugByCodename,
	isAllowPreviewExternalSyllabus,
	isYes,
	redirectToHome,
} from '@/utils'
import {
	ElementModels,
	IContentItemsContainer,
	Responses,
} from '@kontent-ai/delivery-sdk'
import { GetStaticPropsContext } from 'next'
import { compareValueWithMultipleChoiceCodename } from '..'
import { getCodenameBySlugYear } from '../slug-year-utils'
import { isFocusarea, isOptionList } from '../type_predicates'
import { isLifeSkillFocusAreaOrOptionListOrOutcome } from './../focusarea/index'

export const isStage6Syllabus = (syllabus: Syllabus) =>
	syllabus.elements.stages__stages.value.some((s) => s.codename === 'stage_6')

export const isLifeSkillSyllabus = (syllabus: Syllabus) =>
	compareValueWithMultipleChoiceCodename(
		syllabus.elements.syllabus_type__items,
		'life_skills',
	)
export const hasLifeSkillFocusAreasOnSyllabus = (
	syllabus: Syllabus,
	linkedItems: IContentItemsContainer,
) => {
	const focusAreas = getLinkedItems(
		syllabus.elements.focus_areas,
		linkedItems,
	)
	const hasLifeSkillFocusArea = focusAreas
		?.filter(isFocusarea)
		?.some(isLifeSkillFocusAreaOrOptionListOrOutcome)
	return hasLifeSkillFocusArea
}

export const hasLifeSkillRelatedSyllabus = (syllabus: Syllabus) =>
	!!syllabus.elements?.relatedlifeskillssyllabus?.value?.length

export const getStageCodenameFromStageSlug = (
	syllabusResponse: Responses.IViewContentItemResponse<Syllabus>,
	slug: string,
): TaxoStageWithLifeSkill => {
	const { item: _syllabus, linkedItems } = syllabusResponse
	const isStage6Syl = isStage6Syllabus(_syllabus)
	const hasLifeSkillSyl = hasLifeSkillFocusAreasOnSyllabus(
		_syllabus,
		linkedItems,
	)
	const codename = getCodenameBySlugYear(slug)
	if (
		codename === 'life_skills' ||
		Object.values(STAGE_YEARS)
			.flatMap((y) => y)
			.includes(codename as TaxoStageYear)
	) {
		if (hasLifeSkillSyl || isStage6Syl) {
			return codename === 'life_skills' ? codename : 'stage_6'
		}
	}
	return codename as TaxoStageWithLifeSkill
}

export const getYearCodenameFromStageSlug = (
	syllabusResponse: Responses.IViewContentItemResponse<Syllabus>,
	slug: string,
): TaxoStageYearWithLifeSkill | '' => {
	const { item: _syllabus, linkedItems } = syllabusResponse
	const isStage6Syl = isStage6Syllabus(_syllabus)
	const hasLifeSkillSyl = hasLifeSkillFocusAreasOnSyllabus(
		_syllabus,
		linkedItems,
	)
	// replace 'year-' with 'n' to match the codename
	const codename = getCodenameBySlugYear(slug)
	if (hasLifeSkillSyl) {
		return codename === 'life_skills' ? codename : ''
	}
	if (isStage6Syl) {
		return codename as TaxoStageYearWithLifeSkill
	}
	return ''
}

export const getStageAndYearCodenamesFromStageOrYearSlug = (
	syllabusResponse: Responses.IViewContentItemResponse<Syllabus>,
	slug: string,
) => {
	return {
		stageCodename: getStageCodenameFromStageSlug(syllabusResponse, slug),
		yearCodename: getYearCodenameFromStageSlug(syllabusResponse, slug),
	}
}

const getFocusAreas = (
	syllabusResponse: Responses.IViewContentItemResponse<Syllabus>,
	yearCodename: TaxoStageYearWithLifeSkill | '',
) => {
	const { item: syllabus, linkedItems } = syllabusResponse
	const isStage6Syl = isStage6Syllabus(syllabus)
	const hasLifeSkillRelatedSyl = hasLifeSkillRelatedSyllabus(syllabus)

	if (isStage6Syl) {
		if (yearCodename === 'life_skills' && hasLifeSkillRelatedSyl) {
			const relatedLifeSkillSyllabus = getLinkedItems(
				syllabus.elements.relatedlifeskillssyllabus,
				linkedItems,
			)
			return relatedLifeSkillSyllabus.flatMap((syl) => {
				return getLinkedItems(syl.elements.focus_areas, linkedItems)
			})
		}
	}
	return getLinkedItems(syllabus.elements.focus_areas, linkedItems)
}

export const getSyllabusesResponsesToDetermineDefaultFocusArea = async (
	syllabusCodename: string,
	preview = false,
	stageOrYearSlug: string = '',
) => {
	const syllabusResponse = await getItemByCodename<Syllabus>({
		codename: syllabusCodename,
		preview,
		depth: 0,
	})
	const syllabus = syllabusResponse.item

	const syllabusesResponse = await getAllItemsByType<Syllabus>({
		type: contentTypes.syllabus.codename,
		inFilter: {
			element: 'system.codename',
			value: [
				syllabusCodename,
				...syllabus.elements.relatedlifeskillssyllabus.value,
			],
		},
		preview,
		depth: 2,
		elementsParameter: [
			// Syllabus
			contentTypes.syllabus.elements.focus_areas.codename,
			contentTypes.syllabus.elements.key_learning_area__items.codename,

			// Syllabus - Focus area
			contentTypes.focusarea.elements.title.codename,
			contentTypes.focusarea.elements.syllabus_type__items.codename,
			contentTypes.focusarea.elements.stages__stages.codename,
			contentTypes.focusarea.elements.stages__stage_years.codename,
			contentTypes.focusarea.elements.outcomes.codename,
			contentTypes.focusarea.elements.contentgroups.codename,
			contentTypes.focusarea.elements.syllabus.codename,
			contentTypes.focusarea.elements.course.codename,
			contentTypes.optionslist.elements.focus_area_options.codename,

			// Syllabus - Focus area - Outcomes
			contentTypes.outcome.elements.code.codename,
			contentTypes.outcome.elements.description.codename,
			contentTypes.outcome.elements.isoverarching.codename,

			// Test
			contentTypes.syllabus.elements.courses.codename,
			contentTypes.course.elements.slug.codename,
			contentTypes.course.elements.display_mode.codename,
			contentTypes.course.elements.content_accordion.codename,
			contentTypes.course.elements.content_accordion_title.codename,
		],
	})

	return {
		syllabusResponse,
		syllabusesResponse,
	}
}

export const getStaticPropsForRedirectToDefaultFocusArea = async (
	context: GetStaticPropsContext,
	syllabusResponses?: {
		syllabusResponse: Responses.IViewContentItemResponse<Syllabus>
		syllabusesResponse: Responses.IListContentItemsResponse<Syllabus>
	},
) => {
	const _isAllowPreviewExternalSyllabus = isAllowPreviewExternalSyllabus()
	const { params, preview } = context
	const {
		learningarea: learningAreaSlug,
		syllabus: syllabusSlug,
		stage: stageOrYearSlug,
		focusarea: afterStageSlugs,
		defaultYearCodename,
		courseCodename,
	} = params

	const isSyllabusCourse = !!(params.isSyllabusCourse == 'true')

	const [focusAreaSlug] = afterStageSlugs || []

	const syllabusCodename = getCodenameBySlug(syllabusSlug as string)

	const _syllabusResponses =
		syllabusResponses ||
		(await getSyllabusesResponsesToDetermineDefaultFocusArea(
			syllabusCodename,
			preview,
		))
	if (!_syllabusResponses.syllabusResponse.item) {
		return redirectToHome()
	}

	if (isSyllabusCourse && !defaultYearCodename) {
		return redirectToHome()
	}

	// get the syllabus
	const syllabus = _syllabusResponses.syllabusResponse.item

	const isStage6Syl = isStage6Syllabus(syllabus)
	if (_isAllowPreviewExternalSyllabus) {
		// if allow preview external syllabus, redirect to home if not allow preview and do redirect
		if (
			isYes(syllabus.elements.doredirect) &&
			!isYes(syllabus.elements.allowpreview)
		) {
			return redirectToHome()
		}
	} else {
		// if not allow preview external syllabus, redirect to home if do redirect
		if (isYes(syllabus.elements.doredirect)) {
			return redirectToHome()
		}
	}

	const calculatedSlug = isSyllabusCourse
		? defaultYearCodename
		: stageOrYearSlug

	const { stageCodename, yearCodename } =
		getStageAndYearCodenamesFromStageOrYearSlug(
			_syllabusResponses.syllabusResponse,
			calculatedSlug as string,
		)

	const focusAreas = getStageOrYearFocusAreas(
		_syllabusResponses.syllabusesResponse.items,
		stageCodename,
		yearCodename,
		_syllabusResponses.syllabusesResponse.linkedItems,
		isStage6Syl,
	)

	const syllabusCoursesItems = getLinkedItems(
		syllabus.elements.courses,
		_syllabusResponses.syllabusesResponse.linkedItems,
	)

	const currentSyllabusCodename = syllabusCoursesItems.find(
		(course) => course.system.codename === courseCodename,
	)?.system.codename

	const filteredFocusAreaArray = []
	focusAreas.forEach((item: Focusarea) => {
		if (isSyllabusCourse) {
			item.elements.course.value[0] === currentSyllabusCodename
				? filteredFocusAreaArray.push(item)
				: ''
		} else if (currentSyllabusCodename) {
			item.elements.course.value[0] === currentSyllabusCodename
				? filteredFocusAreaArray.push(item)
				: ''
		}
	})

	const firstFocusArea =
		isSyllabusCourse || currentSyllabusCodename
			? syllabusCoursesItems && filteredFocusAreaArray.length > 0
				? filteredFocusAreaArray[0]
				: null
			: focusAreas[0]
	const firstFocusAreaSlug = getSlugByCodename(
		firstFocusArea?.system.codename || '',
	)

	let _focusAreaSlug = focusAreaSlug
	if (firstFocusArea) {
		// focus area slug should take from first focus area's code element or codename
		_focusAreaSlug = firstFocusAreaSlug
	}

	if (!_focusAreaSlug) {
		return redirectToHome()
	}

	let _focusAreaOptionSlug = ''
	if (isOptionList(firstFocusArea)) {
		const firstOptionCodename =
			firstFocusArea.elements.focus_area_options.value[0] || ''
		_focusAreaOptionSlug = `/${getSlugByCodename(firstOptionCodename)}`
	}

	return {
		redirect: {
			destination: `/learning-areas/${learningAreaSlug}/${syllabusSlug}/content/${stageOrYearSlug}/${_focusAreaSlug}${_focusAreaOptionSlug}`,
			permanent: false,
		},
	}
}

// redirect to 404 if the syllabus is not allowed to be (pre)viewed
export const getStaticPropsForRedirectTo404ForNonPreviewedSyllabus = (
	syllabus: Syllabus,
	redirectPath = '/404',
) => {
	const isRedirect = isAllowPreviewExternalSyllabus()
		? isYes(syllabus.elements.doredirect) &&
		  !isYes(syllabus.elements.allowpreview)
		: isYes(syllabus.elements.doredirect)
	if (isRedirect) {
		return {
			redirect: {
				destination: redirectPath,
				permanent: false,
			},
		}
	}
	return null
}

export const getRedirectPropsForFocusAreaNotMatchSyllabus = (
	params: CommonSyllabusPathParams,
	syllabus: Syllabus,
	linkedItems: IContentItemsContainer,
	redirectPath = '/404',
) => {
	const {
		learningarea: learningAreaSlug,
		syllabus: syllabusSlug,
		stage: stageOrYearSlug,
		tab: tabSlug,
		afterStageSlugs,
	} = params
	const [focusAreaOrOptionListSlug, focusAreaOptionSlug] = afterStageSlugs

	let stageOrYearCodename = getCodenameBySlugYear(stageOrYearSlug)
	let focusAreaCodename = getCodenameBySlugYear(focusAreaOrOptionListSlug)
	let courseSlug = ''
	let isCourseSlugCorrect = true

	// if it's n11_ or n12_, just get the n11 or n12
	if (new RegExp(/n\d{2}_/).test(stageOrYearCodename)) {
		const stageOrYearSlugSplit = stageOrYearCodename.split('_')
		stageOrYearCodename = stageOrYearSlugSplit?.[0]
		courseSlug = getSlugByCodename(stageOrYearSlugSplit?.[1] || '')
	}

	const isLifeSkillStage = stageOrYearCodename === 'life_skills'

	const focusAreaOrOptionLists = getLinkedItems(
		syllabus.elements.focus_areas,
		linkedItems,
	)

	const filterFn = (
		s: ElementModels.TaxonomyTerm<TaxoStage | TaxoStageYear>,
	) =>
		isLifeSkillStage
			? ['stage_4', 'stage_5', 'stage_6'].includes(s.codename)
			: s.codename === stageOrYearCodename

	const relatedLifeSkillsSyllabuses =
		getLinkedItems(
			syllabus.elements.relatedlifeskillssyllabus,
			linkedItems,
		) || []

	const relatedLsFocusAreas = relatedLifeSkillsSyllabuses.flatMap((syl) => {
		return getLinkedItems(syl.elements.focus_areas, linkedItems)
	})

	const focusAreaOrOptionList = [
		...focusAreaOrOptionLists,
		...relatedLsFocusAreas,
	]
		.filter((fa) => {
			if (isLifeSkillStage) {
				return isLifeSkillFocusAreaOrOptionListOrOutcome(fa)
			}
			return true
		})
		.find((fa) => {
			return (
				fa.system.codename === focusAreaCodename &&
				(fa.elements.stages__stages.value.some(filterFn) ||
					fa.elements.stages__stage_years.value.some(filterFn))
			)
		})
	let focusAreaOrFocusAreaOption: Focusarea | Optionslist | Focusareaoption =
		focusAreaOrOptionList

	const isFaOptionList = isOptionList(focusAreaOrOptionList)
	if (isFaOptionList && focusAreaOptionSlug) {
		const focusAreaOptions = getLinkedItems(
			focusAreaOrOptionList.elements.focus_area_options,
			linkedItems,
		)
		focusAreaOrFocusAreaOption = focusAreaOptions.find(
			(option) =>
				option.system.codename ===
				getCodenameBySlugYear(focusAreaOptionSlug),
		)
	}

	const isLifeskillFA = focusAreaOrOptionList
		? isLifeSkillFocusAreaOrOptionListOrOutcome(focusAreaOrOptionList)
		: false

	if (courseSlug && focusAreaOrFocusAreaOption) {
		const courses =
			isFocusarea(focusAreaOrFocusAreaOption) || isFaOptionList
				? getLinkedItems(
						(focusAreaOrFocusAreaOption as Focusarea | Optionslist)
							.elements.course,
						linkedItems,
				  )
				: []

		// if it's not life skills FA, check if the course is life skills
		isCourseSlugCorrect =
			isLifeskillFA ||
			courses.some(
				(course) =>
					course.elements.slug.value === courseSlug &&
					(course.elements.stages.value.some(filterFn) ||
						course.elements.stage_years.value.some(filterFn)),
			)
	}

	if (
		!(
			focusAreaOrFocusAreaOption &&
			// kla
			syllabus.elements.key_learning_area__items.value
				.map(byTaxoCodename)
				.includes(
					getCodenameBySlugYear(
						learningAreaSlug,
					) as TaxoKeyLearningArea,
				) &&
			// syllabus
			(getCodenameBySlugYear(syllabusSlug) as TaxoKeyLearningArea) &&
			// content
			tabSlug === 'content' &&
			// extension
			isCourseSlugCorrect &&
			// focus area or options list
			getCodenameBySlugYear(focusAreaOrOptionListSlug) ===
				focusAreaOrOptionList.system.codename
		)
	) {
		return {
			redirect: {
				destination: redirectPath,
				permanent: false,
			},
		}
	}
	return null
}
